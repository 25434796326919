<template>
  <div class="app-container user-container">
    <div class="add-container">
      <el-form inline label-width="100px">
        <el-form-item label="所属使馆：" v-if="isAdmin">
          <el-select v-model="listQuery.dipm_id" placeholder="请选择" @change="fetchData()" clearable>
            <el-option value="" label="全部"></el-option>
            <el-option v-for="item in dipmList" :key="item.id" :value="item.id" :label="item.name"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="doAdd({})" icon="el-icon-plus">添加类别</el-button>
        </el-form-item>
      </el-form>
    </div>

    <!-- 表格区域  -->
    <el-table
      :data="list"
      v-loading="loading"
      border
      fit
      hightlight-current-row
    >
      <el-table-column label="所属使馆" width="220" prop="dipm_name" v-if="isAdmin" />
      <el-table-column label="类别名称" width="250" prop="type_name" />
      <el-table-column label="类别名称(英文）" width="250" prop="type_name_en" />
      <el-table-column label="类别描述" min-width="120" prop="remark" />
      <el-table-column label="费用" width="120" align="center" prop="price" />
      <el-table-column label="加急费用" width="120" align="center" prop="urgent_fee" />
      <el-table-column label="结算币种" width="150" align="center">
        <template slot-scope="{ row }">
          <span>{{ row.pay_type }}({{ row.pay_type_name }})</span>
        </template>
      </el-table-column>
      <el-table-column label="添加时间" width="180" align="center" prop="created_time" />
      <el-table-column label="操作" width="120" align="center">
        <template slot-scope="{ row }">
          <el-link type="primary" @click="doAdd(row)">编辑</el-link>
          <el-divider direction="vertical"></el-divider>
          <el-popconfirm
            title="您确定要删除该类别吗？"
            @confirm="doDelete(row)"
          >
            <el-link type="danger" slot="reference">删除</el-link>
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>

    <el-pagination
      :current-page="listQuery.page"
      :page-size="listQuery.page_size"
      :total="total"
      style="padding: 32px 0;"
      layout="total, prev, pager, next, jumper"
      @current-change="fetchData"/>

    <!-- 添加类别 -->
    <el-dialog
      :title="postData.id > 0 ? '编辑类别' : '添加类别'"
      :visible.sync="dialogVisible"
      destroy-on-close
      :key="dialogIndex"
      @close="dialogIndex++"
    >
      <el-form label-width="150px" :model="postData" :rules="rules" ref="postForm">
        <el-form-item label="所属使馆：" v-if="isAdmin" prop="dipm_id">
          <el-select v-model="postData.dipm_id" placeholder="请选择">
            <el-option v-for="item in dipmList" :key="item.id" :value="item.id" :label="item.name"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="类别名称：" prop="type_name">
          <el-input v-model="postData.type_name" placeholder="类别名称" style="width: 80%"></el-input>
        </el-form-item>
        <el-form-item label="类别名称（英文）：" prop="type_name_en">
          <el-input v-model="postData.type_name_en" placeholder="类别名称（英文）" style="width: 80%"></el-input>
        </el-form-item>
        <el-form-item label="费用：" prop="price">
          <el-input-number :min="0" v-model="postData.price" placeholder="填写费用"  style="width: 60%"></el-input-number>
          <span class="mlm">{{feeTypeName}}</span>
        </el-form-item>
        <el-form-item label="加急费用：" prop="urgent_fee">
          <el-input-number :min="0" v-model="postData.urgent_fee" placeholder="填写加急费用" style="width: 60%"></el-input-number>
          <span class="mlm">{{feeTypeName}}</span>
        </el-form-item>
        <el-form-item label="备注：">
          <el-input type="textarea" :rows="2" placeholder="请填写类别备注事项" v-model="postData.remark" style="width: 80%"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="onSubmit(false)" :loading="uploading">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script type="text/javascript">
import api from '@/api/type'
import dipm from '@/api/dipm'
import { mapGetters, mapState } from 'vuex'
const listQuery = {
  dipm_id: '',
}
export default {
  data() {
    return {
      listQuery: { ...listQuery },
      loading: false,
      list: [],
      total: 0,

      postData: {},
      dialogVisible: false,
      dialogIndex: 0,
      uploading: false,
      rules: {
        dipm_id: [{ required: true, message: '所属使馆必选' }],
        type_name: [{ required: true, message: '类别名称必填' }],
        type_name_en: [{ required: true, message: '类别名称（英文）必填' }],
        price: [{ required: true, type: 'number', message: '费用必填' }],
      },
      dipmList: [],
    }
  },
  computed: {
    ...mapGetters(['isAdmin']),
    ...mapState(['user']),
    feeTypeName() {
      if (this.isAdmin) {
        return this.postData.dipm_id && this.dipmList.find(v => v.id === this.postData.dipm_id).pay_type_name || ''
      }
      return this.user.diplomaticDTO.pay_type_name || ''
    }
  },
  created() {
    this.fetchData()
    if (this.isAdmin) {
      this.getDipmList()
    }
  },
  methods: {
    getDipmList() {
      dipm.getList({ page_size: 999 })
      .then(res => {
        this.dipmList = res.list || []
      })
    },
    fetchData(page = 1) {
      this.listQuery.page = page
      this.loading = true
      api.getList(this.listQuery)
      .then(res => {
        this.list = res.list || []
        this.total = res.total
        this.loading = false
      })
    },
    onReset() {
      this.listQuery = { ...listQuery }
      this.fetchData()
    },
    doAdd(row) {
      this.dialogVisible = true
      this.postData = { price: 0, urgent_fee: 0, ...row }
    },
    onSubmit() {
      this.$refs.postForm.validate(valid => {
        if (!valid) return false
        this.uploading = true
        if (this.postData.id > 0) {
          api.update(this.postData.id, this.postData)
          .then(() => {
            this.$message.success('修改成功')
            this.fetchData()
            this.uploading = false
            this.dialogVisible = false
          })
          .catch(() => {
            this.uploading = false
          })
          return
        }
        api.add(this.postData)
        .then(id => {
          this.$message.success('添加成功')
          this.fetchData()
          this.uploading = false
          this.dialogVisible = false
        })
        .catch(() => {
          this.uploading = false
        })
      })
    },
    doDelete(row) {
      api.del(row.id)
      .then(() => {
        this.$message.success('删除成功')
        this.list = this.list.filter(v => v.id !== row.id)
      })
    },
  }
}
</script>
